import {formActions} from '@/enums/formActions'

const initState = () => ({
  metals: [],
  itemTypes: [],
  attributes: [],
  metalSubItemVariants: [],
  ringSizeDialogShown: false,
})

export const state = initState

export const getters = {
  getMetals: (state) => state.metals,
  getItemTypes: (state) => state.itemTypes,
  getAttributes: (state) => state.attributes,
}

export const mutations = {
  setMetals(state, {metals}) {
    state.metals = metals
  },
  setItemTypes(state, {itemTypes}) {
    state.itemTypes = itemTypes
  },
  setAttributes(state, {attributes}) {
    state.attributes = attributes
  },
  setMetalSubItemVariants(state, {metalSubItemVariants}) {
    state.metalSubItemVariants = metalSubItemVariants
  },
  setRingSizeDialogShown(state, ringSizeDialogShown) {
    state.ringSizeDialogShown = ringSizeDialogShown
  },
}

export const actions = {
  fetchMetals({commit}) {
    return this.$axios.$get('/Metals/ShownInCatalog').then((metals) => {
      commit('setMetals', {
        metals: metals.map((element) => {
          return {text: element.name, value: element.id}
        }),
      })
    })
  },
  async fetchItemTypes({commit}) {
    return await this.$axios.$get('/ItemTypes/ShownInCatalog').then((itemTypes) => {
      commit('setItemTypes', {
        itemTypes: itemTypes.map((element) => {
          return {text: element.name, code: element.code, value: element.id}
        }),
      })
    })
  },

  async fetchItemTypeByCode({commit}, {filters}){
    let result;
    try{
      result = await this.$axios.$get(`webpos/itemtypes/get-itemtype-by-SKU?${new URLSearchParams(filters).toString()}`)
      if(!result || result.apiMessages.hasErrors || !result.value){
        this.$toast.error("Item type was not found");
        return result
      }
    }
    catch(e){
      this.$toast.error("Error while getting the item");
    }
    return result
  },

  async fetchItemTypeAttributes({commit}, {itemTypeID, showInProductCatalog}) {
    const attributes = await this.$axios.$get(
      `/itemtypes/${itemTypeID}/customattributevalues`
    )
    if (showInProductCatalog) {
      commit('setAttributes', {
        attributes: attributes.filter(
          (x) => x.showInProductCatalog === showInProductCatalog
        ),
      })
    } else {
      commit('setAttributes', {attributes})
    }
  },
  async fetchMetalSubItemVariants({commit}, itemtypeId) {
    await this.$catch(
      async () => {
        const metalSubItemVariants = await this.$axios.$get(
          `/Metals/getMetalSubItemVariants/${itemtypeId}`
        )
        commit('setMetalSubItemVariants', {
          metalSubItemVariants: metalSubItemVariants.map((x) => {
            return {text: x.name, value: x.id}
          }),
        })
      },
      formActions.Load,
      'Metal variants'
    )
  },
  updateAttributes({commit}, attributes) {
    commit('setAttributes', {attributes})
  },

  async getItemTypesForFilter({commit}, {storeID, filteredItemGroupID}){
    let url = '/webpos/itemTypes/get-for-filter';

    url =`${url}/?storeID=${storeID}`

    if(filteredItemGroupID > 0){
      url =`${url}&itemGroupID=${filteredItemGroupID}`
    }
    let result;
    try{
      result = await this.$axios.$get(url)
      if(result && !result.hasErrors && result.value) {
        return result.value.map((itemType) => {
          return {
            text: itemType.text, value: itemType.value
          };
        });
      } else{
        this.$toast.error('Error while getting item types');
      }
    }catch(e){
      this.$toast.error('Error while getting item type');
    }
    return result;
  },

  async getItemTypeByID({commit}, {itemTypeID, attributes}){
    let itemType = null;
    try{
      const result = await  this.$axios.$post(
        `/webpos/itemtypes/${itemTypeID}`,
        attributes
      );

      if(result && !result.hasErrors){
        itemType = result.value;
      }

      else{
        this.$toast.error('Error while getting item type');
      }
    }
    catch(e){
      this.$toast.error('Error while getting item type');
    }

    return itemType;
  },

  async getItemTypes({commit}, itemTypeFilter){
    let itemTypes = [];
    try{
      const result = await  this.$axios.$post(`/webpos/itemtypes`, itemTypeFilter);
      if(result && !result.hasErrors && result.value){
        itemTypes = result.value;
      }
      else{
        this.$toast.error('Error while getting item types');
      }
    }
    catch(e){
      this.$toast.error('Error while getting item types');
    }
    return itemTypes;
  },
}
