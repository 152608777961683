import {mapFilters} from "@/utils/helpers";

const initState = () => ({
  orderCreatingInProgress : false
})

export const state = initState

export const getters = {
}

export const mutations = {
  setOrderCreatingInProgress(state, inProgress){
    state.orderCreatingInProgress = inProgress
  }
}

export const actions = {
  async createTradeIn({commit}, tradeIn) {
    let insertedCreditMemo = null
    try {
      commit('setOrderCreatingInProgress', true)
      let result = await this.$axios.$post(`/webpos/trade-in`, tradeIn);

      if (result && result.apiMessages.hasErrors && result.apiMessages.serverErrors.length > 0) {
        this.$toast.error(result.apiMessages.serverErrors[0]);
      } else if (result.value) {
        insertedCreditMemo = result.value;
      }

    } catch (error) {
      if (error.response && error.response.data && error.response.data.Message) {
        this.$toast.error(error.response.data.Message);
        return;
      }
      this.$toast.error("Error while creating credit memo for trade in item");

    } finally {
      commit('setOrderCreatingInProgress', false)
    }
    return insertedCreditMemo;
  },

  async fetchTradeInItems({commit, state}, {pagingInfo, filters}){
    let tradeInItems = [];

    try{
      let filter = mapFilters(filters, pagingInfo.pageNo, pagingInfo.pageSize, pagingInfo.sortBy, pagingInfo.sortAsc);
      const result = await this.$axios.get(`/webpos/trade-in/?${new URLSearchParams(filter).toString()}`);

      if(result && result.data && !result.data.hasErrors){
        tradeInItems = result.data;
      }
      else{
        this.$toast.error('Error while getting trade in items');
      }
    }
    catch (error) {
      this.$toast.error('Error while getting trade in items');
    }


    return tradeInItems;
  },
}
