import {formActions} from '@/enums/formActions'

const initState = () => ({
  showFilter: false,
  product: {},
  products: [],
  customProduct:{},
  customProducts: [],
  attributes: [],
})

export const state = initState

export const getters = {

}

export const mutations = {

  setProducts(state, {products}) {
    state.products = products
  },
  setProduct(state, {product}){
    state.product = product
  },
  setCustomProducts(state, {products}) {
    state.customProducts = products
  },
  setAttributes(state, {attributes}){
    let attributesWithValues = attributes.map((attr) => {
      let valueStr = `${attr.valueStr ? attr.valueStr + " " + (attr.measureUnitSymbol || '') : '-'}`.trim()

      if (valueStr){
        return {
          id: attr.id,
          code: attr.code,
          name: attr.name,
          valueStr: valueStr,
          value: attr.value,
          file: {
            fileUrl: attr.imageUrl,
          }
      }
      }
    })

    state.attributes = attributesWithValues.filter(a => a != null)

  },

}

export const actions = {


  async fetchProducts({commit}, {catalogID, filters}){
    let products = [];

    let url = `webpos/catalogs/${catalogID}/products?${new URLSearchParams(filters).toString()}`
    try{
      const response = await this.$axios.$get(url)
      if(!response || response.apiMessages.hasErrors){
        this.$toast.error("Error while getting products");
      }
      else{
        products = response.value
      }
    }
    catch(e){
      this.$toast.error("Error while getting products")
    }
    return products;
  },


  async fetchProductAttributes({commit}, {itemTypeID,entityID, showInProductCatalog}) {
    var attributes = await this.$axios.$get(
      `webpos/products/${itemTypeID}/${entityID}/customattributevalues`
    )
    if (showInProductCatalog) {
        attributes = attributes.filter(attr => attr.showInProductCatalog)
    }
    return attributes.map((attr) => {
      let valueStr = `${attr.valueStr ? attr.valueStr + " " + (attr.measureUnitSymbol || '') : '-'}`.trim()
      return {
        id: attr.id,
        code: attr.code,
        name: attr.name,
        valueStr: valueStr,
        value: attr.value,
        file: {
          fileUrl: attr.imageUrl,
        },
        attributeTypeCode: attr.attributeTypeCode,
      }
    });
  },

}
