const initState = () => ({
})

export const state = initState

export const getters = {
}

export const mutations = {

}

export const actions = {
  async getEmailPreview({commit, state}, emailData) {
    var email = null
    try{
      const result = await this.$axios.$get(`/webpos/email/preview?${new URLSearchParams(emailData).toString()}`, emailData);
      if(!result || result.hasErrors){
        this.$toast.error('Error while generating email preview');
      }
      else{
        email = result.value;
      }
    }
    catch{
      this.$toast.error('Error while generating email preview');
    }
    return email;
  },

  async sendEmail({commit, state}, emailPreview) {
    try{
      const result = await this.$axios.$post(`/webpos/email/send`, emailPreview);
      if(result && !result.hasErrors){
        this.$toast.success('Email sent successfully');
        return true;
      }
      else{
        if(result && result.errorMessages && result.errorMessages.length > 0){
          this.$toast.error(result.errorMessages[0].value);
        }
        else{
          this.$toast.error('Error while sending email');
        }
      }
    }
    catch{
      this.$toast.error('Error while sending email');
    }
    return false;
  }
}
