import {mapFilters} from "@/utils/helpers";
import * as url from "url";

const initState = () => ({
})

export const state = initState
export const actions= {

  async fetchShippingMethods({commit, state}) {
    const url = `/webpos/shipping-method/`
    let shippingMethods = []
    try {
      const result = await this.$axios.$get(url)
      if(result && result.apiMessages.hasErrors){
        this.$toast.error("Error while getting shipping methods")
      }
      else{
        shippingMethods = result.value.map(({id, name, code, price}) => (
          {
            value: id,
            text: name,
            code,
            price
          }));
      }
    }
    catch (error) {
      this.$toast.error("Error while getting shipping methods")
    }

    return shippingMethods
  }
}
