import {jobTypeCodes} from "@/enums/jobTypeCodes";
import {labelListCodes} from "@/enums/labelListCodes";

const initState = () => ({
})

export const state = initState

export const actions= {

  async fetchPriorityTypes({commit, state}) {
    const url = `/webpos/joborder-priority/`;
    let priorityTypes = [];
    let labelListCode = labelListCodes.JobPriorities;
    try {
      const result = await this.$axios.$get(url, { params: { code: labelListCode } });

      if (result) {
        priorityTypes = result;
      } else {
        this.$toast.error('Error while getting priority types.');
      }
    } catch (error) {
      console.error('Error while fetching priority types:', error);
      this.$toast.error('Error while getting priority types.');
    }
    return priorityTypes;
  }
}
