import {formActions} from '@/enums/formActions'
import {value} from "lodash/seq";

const initState = () => ({})

export const state = initState

export const getters = {}

export const mutations = {}

export const actions = {
  fetchFieldById({commit}, {id}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const field = await this.$axios.$get(`/manage/component-fields/${id}`)
          resolve({field})
        },
        formActions.Load,
        'field'
      )
    })
  },
  fetchFieldTypes({commit}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const fieldTypes = await this.$axios.$get(
            `/manage/component-fields/types`
          )
          resolve({fieldTypes})
        },
        formActions.Load,
        'fieldType'
      )
    })
  },

  async fetchAllFields({commit}) {
    return await this.$axios.$get('manage/contents/allfields')
  },

  async saveField({commit}, {field}) {
    await this.$catch(
      async () => {
        await this.$axios.$post(`/manage/component-fields/save`, field)
        this.$toast.success(
          this.$i18n.t('saved_successfully').replace('{name}', field.name)
        )
      },
      formActions.Save,
      field.name
    )
  },
}
