import {formActions} from "@/enums/formActions";

const initState = () => ({
  applicationCurrencies: [],
})

export const state = initState

export const getters = {
}

export const mutations = {
  setApplicationCurrencies(state, {applicationCurrencies}) {
    state.applicationCurrencies = applicationCurrencies
  },
}

export const actions = {
  async fetchApplicationCurrencies({}){
    let currencies = []
    const url = `/webpos/settings`;

    try{
      let response = await this.$axios.$get(url);
      if(response && !response.hasErrors){
        currencies = response.value;
      }
      else {
        this.$toast.error('Error while getting application currencies');
      }
    }
    catch {
      this.$toast.error('Error while getting application currencies');
    }

    return currencies;

  }
}
