
const initState = () => ({
})

export const state = initState

export const getters = {
}

export const mutations = {
}

export const actions = {
  async getCustomAttributesByListID({}, {customAttributeListID, filter}) {
    let customAttributes = []
    try {
      let result = await this.$axios.$get(`/webpos/custom-attribute-list/${customAttributeListID}?${new URLSearchParams(filter).toString()}`);

      if (result && result.apiMessages.hasErrors && result.apiMessages.serverErrors.length > 0) {
        this.$toast.error("Error while getting custom attributes");
      }
      else if (result.value) {
        customAttributes = result.value;
      }

    } catch (error) {
      this.$toast.error("Error while getting custom attributes");
    }
    return customAttributes;
  },

}
