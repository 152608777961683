import {formActions} from "@/enums/formActions";
import {frontends} from "@/enums/frontends";


const initState = () => ({
  modules: []
})

export const state = initState;

export const mutations ={
  setModules(state, {fetchedModules}){
    state.modules = fetchedModules;
  },
}
export const actions ={
  async fetchModules({commit, state}) {
    try{
      const fetchedModules =  await this.$axios.$get(`webpos/modules/getApplicationModules`);
      if (fetchedModules == null) {this.$toast.error("Error while getting modules from database!");}
      commit('setModules', {fetchedModules});
    }
    catch{
      this.$toast.error("Error while getting modules from database!");
    }
  },

  async saveModule({commit}, {module}){
    try{
      module.visibleInMenu = !module.visibleInMenu
      await this.$axios.$post(`webpos/modules/saveApplicationModule`, module);
    }
    catch{
      this.$toast.error("Error while saving the new settings for the application module.");
    }
  }
}
