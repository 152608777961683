
const initState = () => ({
  countries: [],
  states: [],
  selectedState: '',
})

export const state = initState

export const getters = {
  getCountries: (state) => () => {
    return state.countries
  },
  getStates: (state) => ()=> {
    return state.states
  },
  getCountry: (state) => (countryId) => {
    return state.countries.find((x) => x.value === countryId)
  },
  getState: (state) => (stateId) => {
    return state.states.find((x) => x.value === stateId)
  },
}

export const mutations = {
  setCountries(state, {countries}) {
    state.countries = countries
  },
  setStatesByCountryId(state, {object}) {
    state.statesByCountry = state.states.filter(
      (currentState) => currentState.parentId === object.value
    )
    console.log(state.statesByCountry)
    state.selectedState = ''
  },
  setStates(state, {states}) {
    state.states = states
  },
}

export const actions = {
  async fetchCountries({commit, getters}) {
    let countries = getters.getCountries()
    if(countries && countries.length > 0){
      return countries
    }

    countries = []
    try{
      let countriesList = await this.$axios.$get('Countries')
      const apiCountries = countriesList.map((element) => {
        return {text: element.name, value: element.id, code: element.code}
      })
      commit('setCountries', {
        countries: apiCountries,
      })
      countries = apiCountries
    }
    catch{
      this.$toast.error('Error while getting the states');
    }
    return countries
  },

  async fetchAllStates({commit, getters}) {
    let states = getters.getStates()
    if(states && states.length > 0){
      return states
    }

    states = []
    try{
      let statesList = await this.$axios.$get('Countries/states')
      const apiStates = statesList.map((element) => {
        return {
          text: element.name,
          value: element.id,
          parentId: element.parentID,
        }
      })
      commit('setStates', {
        states: apiStates,
      })
      states = apiStates
    }
    catch{
      this.$toast.error('Error while getting the states');
    }
    return states
  },
}
