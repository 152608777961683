import {formActions} from '@/enums/formActions'
import {mapFilters} from "@/utils/helpers";

const initState = () => ({
  customerBalances: [],
  saleReports: [],
  salesByItemsReports: []
})

export const state = initState

export const getters = {
}

export const mutations = {
  setCustomerBalances(state, {reports}){
    state.customerBalances = reports
  },
  addCustomerBalances(state,{reports}){
    reports.forEach(r => state.customerBalances.items.push(r))
  },
  resetCustomerBalances(state){
    state.customerBalances.items = []
    state.customerBalances.itemCount = 0
  },

  setSalesByItemsReports(state, {reports}){
    state.salesByItemsReports = reports
  },
  addSalesByItemsReports(state,{reports}){
    reports.forEach(r => state.salesByItemsReports.push(r))
  },

  setSaleReports(state, {reports}){
    state.saleReports = reports
  },
  addSaleReports(state,{reports}){
    reports.forEach(r => state.saleReports.push(r))
  },
}

export const actions = {
  async getCustomerBalances({commit, dispatch}, {filters, options}){
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          let url = `/webpos/reports/customerBalances`
          let filter = {}
          filters.map((f) => {
            if (f.value) {
              if(Array.isArray(f.value)){
                Object.assign(filter, {[f.property]: f.value.toString()})
              }
              else{
                Object.assign(filter, {[f.property]: f.value})
              }
            }
          })
          Object.assign(filter, {pageNo: options.page - 1})
          Object.assign(filter, {pageSize: options.itemsPerPage})
          Object.assign(filter, {sortBy: options.sortBy[0] || null})
          Object.assign(filter, {sortAsc: !options.sortDesc[0]})


          let reports = await this.$axios.$post(url,filter)
          reports = await dispatch('mapCustomerBalances', {response: reports})
          commit('setCustomerBalances', {reports: reports})
          resolve(reports)
        },
        formActions.Load,
        'reports'
      )
    })
  },
  async getSaleReports({commit, dispatch}, {filters, pagingInfo})  {
    let saleReportItems = []
    try{
      let filter = mapFilters(filters, pagingInfo.pageNo, pagingInfo.pageSize, pagingInfo.sortBy, pagingInfo.sortAsc);
      let result = await this.$axios.post(`/webpos/reports/sale-reports`, filter);
      if (result && !result.data.hasErrors) {
        saleReportItems = result.data;
      }
      else {
        this.$toast.error("Error while getting sale reports");
      }
    }
    catch{
      this.$toast.error("Error while getting sale reports");
    }
    return saleReportItems;
  },

  async getSaleReportsTotal({}, {filters, pagingInfo})  {
    let getSaleReportsTotal = {}

    try{
      let filter = mapFilters(filters, pagingInfo.pageNo, pagingInfo.pageSize, pagingInfo.sortBy, pagingInfo.sortAsc);
      let result = await this.$axios.post('/webpos/reports/sale-reports-totals', filter);
      if (result && !result.data.hasErrors) {
        getSaleReportsTotal = result.data.value;
      }
      else {
        this.$toast.error("Error while getting sale reports total");
      }
    }
    catch{
      this.$toast.error("Error while getting sale reports total");
    }
    return getSaleReportsTotal;
  },



  async getSalesByItemsReports({commit, dispatch}, {filters, pagingInfo})  {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          let url = `/webpos/reports/sales-by-items-reports`

          let filter = mapFilters(filters, pagingInfo.pageNo, pagingInfo.pageSize, pagingInfo.sortBy, pagingInfo.sortAsc);
          let reports = await this.$axios.$post(url,filter)

          reports = await dispatch('mapSalesByItemsReports', {response: reports})
          commit('setSalesByItemsReports', {reports: reports})
          resolve(reports)

        },
        formActions.Load,
        'reports'
      )
    })
  },


  async getSoldItemsByItemGroup({commit},{filters}){
    let result
    try{
      let url = `webpos/reports/sold-item-types/?itemGroupID=${filters.itemGroupID}`
      if(filters.storeID > 0){
        url =`${url}&storeID=${filters.storeID}`
      }
      result = await this.$axios.$get(url);
      if(result.hasErrors || result.errorMessage){
        this.$toast.error("Error while getting items by item group!");
      }
    }catch(e){
      this.$toast.error("Error while getting items by item group!");
    }
    return result
  },

  async getSalesHistoryReport({commit, dispatch}, {filters, pagingInfo, refreshTotals = true})  {
    try{
      let url = `/webpos/reports/sales-history?refreshTotals=${refreshTotals}`
      let filter = mapFilters(filters, pagingInfo.pageNo, pagingInfo.pageSize, pagingInfo.sortBy, pagingInfo.sortAsc);

      let reports = await this.$axios.$post(url,filter)
      if (reports.hasErrors){
        this.$toast.error("Error while getting sales history!");
        return { salesHistory: []}
      }
      else
      {
        return reports.value;
      }
    }
    catch (e) {
      this.$toast.error("Error while getting sales history!");
      return {salesHistory: []}
    }

  },

  async fetchCustomerEvents({commit, dispatch}, {filters, pagingInfo}) {
    let events = []
    try{
      let filter = mapFilters(filters, pagingInfo.pageNo, pagingInfo.pageSize, pagingInfo.sortBy, pagingInfo.sortAsc);
      let result = await this.$axios.post(`webpos/reports/customer-events`, filter);
      if (result && !result.data.hasErrors) {
        events = result.data;
      }
      else {
        this.$toast.error("Error while getting customer events");
      }
    }
    catch{
      this.$toast.error("Error while getting customer events");
    }
    return events;
  },

  async fetchCustomerSaleDetail({commit, dispatch}, {customerID , storeID}){
    let result;
    try{
      let url = `webpos/reports/customer-sale-detail/?customerID=${customerID}`
      if(storeID > 0){
        url =`${url}&storeID=${storeID}`
      }
      result = await this.$axios.get(url)
      if (!result || result.data.hasErrors) {
        this.$toast.error("Error while getting sales data for customer")
      }
    }catch(e){
      console.error(e)
      this.$toast.error("Error while getting sales data for customer")
    }
    return result
  },

  async fetchWishlistItems({commit, dispatch}, {filters, pagingInfo}) {
    let wishlistItems = []
    try{
      let filter = mapFilters(filters, pagingInfo.pageNo, pagingInfo.pageSize, pagingInfo.sortBy, pagingInfo.sortAsc);
      let result = await this.$axios.post(`webpos/reports/wishlist-items`, filter);
      if (result && !result.data.hasErrors) {
        wishlistItems = result.data;
      }
      else {
        this.$toast.error("Error while getting wishlist items");
      }
    }
    catch{
      this.$toast.error("Error while getting wishlist items");
    }
    return wishlistItems;
  },

  async fetchLayawayListItems({commit, dispatch}, {filters, pagingInfo}){
    let layawaysListItems = []
    try{
      let filter = mapFilters(filters,pagingInfo.pageNo, pagingInfo.pageSize, pagingInfo.sortBy, pagingInfo.sortAsc);
      let result = await this.$axios.post(`webpos/reports/layaway-items`, filter);
      if (result && !result.data.hasErrors) {
        layawaysListItems = result.data;
      }
      else {
        this.$toast.error("Error while getting layaway items");
      }

    }
    catch{
      this.$toast.error("Error while getting layaway items");
    }
    return layawaysListItems;
  },

  async mapCustomerBalances({commit},{response}){
    let reportResponse = response.data ? response.data : response
    let reports = {
      items: [],
      itemCount: null
    }
    reportResponse.items.map(x => {
     let customerBalance =  {
        id: x.id,
        customerName: x.customerName,
        emailAddress: x.emailAddress,
        phoneNumber: x.phoneNumber,
        paid: x.paid,
        balance: x.balance,
        totalValue: x.totalValue
      }
      reports.items.push(customerBalance)
    })
    reports.itemCount = reportResponse.pagingData.itemCount
    return reports
  },

  async mapSaleReports({commit},{response}){
    let reports = []
    response.value.map(x => {
      if (this.$auth.user.userGroups.find(x => x.groupCode === "WEB_POS_ADMIN") != null){
        reports.push({
          customerId: x.customerID,
          customerName: x.companyName ? x.companyName : x.firstName + ' ' + x.lastName,
          totalItemsSold: x.totalItemsSold,
          salesAmount: x.salesAmount,
          balance: x.balance,
          salesPersonName: x.salesPersonName
        })
      }
      else {
        reports.push({
          customerId: x.customerID,
          customerName: x.companyName ? x.companyName : x.firstName + ' ' + x.lastName,
          totalItemsSold: x.totalItemsSold,
          salesAmount: x.salesAmount,
          balance: x.balance
        })
      }

    })
    return reports
  },

  async mapSalesByItemsReports({commit},{response}){
    let reports = []
    response.value.map(x => {
      reports.push({
        itemGroupId: x.itemGroupID,
        itemGroupName: x.itemGroupName,
        itemsSold: x.itemsSold,
        totalSalesForGroup: x.totalSalesForGroup,

      })
    })
    return reports
  }
}
