const initState = () => ({
  formatSettings: [],
})

export const state = initState

export const getters = {
  getFormattingSettings: (state) => (code) => {
    const result = state.formatSettings.find(
      (element) => element.code === code.code
    )
    return result?.value
  },
}

export const mutations = {
  setFormatSettings(state, {formatSettings}) {
    state.formatSettings = formatSettings
  },
  reset(state) {
    Object.assign(state, initState())
  },
}

export const actions = {
  fetchFormatSettings({commit}) {
    return this.$axios.$get('/FormatSettings').then((settings) => {
      commit('setFormatSettings', {
        formatSettings: settings.map((element) => {
          return {code: element.name, value: element.value}
        }),
      })
    })
  },
}
