export const dashboardElements = {
  TOTAL_SALES: 'total_sales',
  SALES_AMOUNT: 'sales_amount',
  DAILY_SALES: 'daily_sales',
  MONTHLY_SALES: 'monthly_sales',
  LAYAWAYS: 'layaways',
  SALES_ORDERS: 'sales_orders',
  CUSTOM_ORDERS: 'custom_orders',
  REPAIRS: 'repairs',
}
