import {formActions} from '@/enums/formActions'
import {mapFilters} from "@/utils/helpers";

const initState = () => ({
})

export const state = initState

export const getters = {
}

export const mutations = {
}

export const actions = {
  async exportCustomerEvents({commit, dispatch}, {filters, pagingInfo}){

    let filter = mapFilters(filters, pagingInfo.pageNo, pagingInfo.pageSize, pagingInfo.sortBy, pagingInfo.sortAsc);
    const response = await this.$axios.post(`webpos/export/customer-events`, filter, {responseType: 'blob'});
    if(response){
      const contentDispositionHeader = response.headers['content-disposition']
      const contentType = response.headers['content-type']

      if (contentDispositionHeader && contentType) {
        const fullNameMatch = contentDispositionHeader.match(/filename\*=(.+)$/);
        let fullName = fullNameMatch ? fullNameMatch[1] : null;
        if (fullName) {
          fullName = decodeURIComponent(fullName.replace("UTF-8''", ''));
        }
        const file = new File(
          [response.data],
          fullName,
          {type: contentType}
        );

        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(file);
        downloadLink.download = fullName;

        document.body.appendChild(downloadLink);

        downloadLink.click();
      }
    }
    else{
      this.$toast.error("Error while exporting customer events");
    }
  },
}
