
const userLoginsInitState = () => ({
   saved: [],
   remembered: null,
  }
)

const initState = () => ({
  userLogins: userLoginsInitState(),
})

export const state = initState


export const mutations = {
  saveLoginInfo(state, loginInfo) {
    if(state.userLogins.saved === undefined) {
      state = initState();
    }
    state.userLogins.saved.push(
      {
        userName: loginInfo.userName,
        password: loginInfo.password,
        pin: loginInfo.pin
      }
    )
  },
  savePinOnUser(state, loginInfo) {
    state.userLogins.saved.find(savedLogin=>savedLogin.userName === loginInfo.userName).pin = loginInfo.pin
  },
  rememberLogin(state, loginInfo) {
    state.userLogins.remembered = loginInfo
  },
  removeRememberedLogin(state) {
    state.userLogins.remembered = null
  }
}

export const actions = {
  updateSavedLogins({commit, state}, loginInfo) {
    if(state.userLogins.saved === undefined) {
      commit('saveLoginInfo', loginInfo)
      return;
    }
    if(!state.userLogins.saved.length || !state.userLogins.saved.some(savedLogin=> savedLogin.userName === loginInfo.userName)) {
      commit("saveLoginInfo", loginInfo)
    }
  },
  async loginWithPinIfExists({commit,state}, pin) {
    if(state.userLogins.saved === undefined || state.userLogins.saved === null) return null;

    if(!state.userLogins.saved.length) return null;

    if (state.userLogins.saved.some(savedLogin => savedLogin.pin === pin)) {
      return state.userLogins.saved.find(savedLogin => savedLogin.pin === pin)
    }
    else {
      let formData = new FormData();
      formData.set("pin",pin);
      return await this.$axios.$post('User/getUsernameByPin', formData).then(userName=> {
        if(state.userLogins.saved.some(savedLogin=>savedLogin.userName === userName)) {
          commit('savePinOnUser', {userName: userName, pin: pin})
          return state.userLogins.saved.find(savedLogin => savedLogin.pin === pin)
        }
      })
    }
  },
  rememberLogin({commit}, loginInfo) {
    commit('rememberLogin', loginInfo)
  },
  removeRememberedLogin({commit}) {
    commit('removeRememberedLogin');
  },
  getRememberedLogin({commit, state}) {
    return state.userLogins.remembered;
  }
}
