const initState = () => ({
  showFilter: false,
  categories: [],
  metals:[],
  category: {},
})

export const state = initState

export const getters = {
  getMetals: (state) => state.metals,
}

export const mutations = {
  setCategories(state, {categories}) {
    state.categories = categories
  },
  reset(state) {
    Object.assign(state, initState())
  },
  setMetals(state, {metals}) {
    state.metals = metals
  },
  setCategory(state, {category}){
    state.category = category
  }
}

export const actions = {
  async getCategories({commit}) {
    const categories = await this.$axios.$get('/ProductCategories?orderByName=true')
    commit('setCategories', {categories})
  },
  async getChildCategories({commit}, parentID) {
    let filter = {
      orderByName: true,
    }
    const categories = await this.$axios.$get(
      '/ProductCategories/childProductCatalogs/' + parentID + '?orderByName=true'
    )
    commit('setCategories', {categories})
  },
  async fetchChildCategories({commit}, parentID) {
    return await this.$axios.$get(
      '/ProductCategories/childProductCatalogs/' + parentID + '?orderByName=true'
    )
  },
  fetchMetals({commit}) {
    return this.$axios.$get('/Metals/ShownInCatalog').then((metals) => {
      commit('setMetals', {
        metals: metals.map((element) => {
          return {text: element.name, value: element.id}
        }),
      })
    })
  },
  async getCatalogById({commit}, catalogId) {
    return await this.$axios.get(`/ProductCategories/${catalogId}`).then(response =>{
      commit('setCategory', {category: response.data})
    })
  }
}
