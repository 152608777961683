export default function ({$auth, store}, inject) {
  $auth.onError((error, name, endpoint) => {
    console.error(name, error)
  })
  $auth.onRedirect((to, from) => {
    if (process.client && from === '/logout') {
      console.log('store in auth', store)
      for (const mutation in store._mutations) {
        if (mutation.startsWith('backoffice') && mutation.endsWith('/reset')) {
          store.commit(mutation)
        }
      }
    }
    console.error('redirecting in auth', from, to)
    // you can optionally change `to` by returning a new value
  })

  if (process.client) {
    inject(
      'hasPermission',
      (permission) =>
        !!(
          $auth.loggedIn && $auth.user.permissions.find((x) => x === permission)
        )
    )
  }
}
