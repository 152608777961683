const initState = () => ({
  registers: [],
})

export const state = initState
export const getters = {
}

export const mutations = {
  setRegisters(state, {registers}) {
    state.registers = registers
  },
}

export const actions = {
  async fetchRegisters({commit}, storeID) {
    if(!storeID || storeID <= 0){
      this.$toast.error('Please select a store first')
      return;
    }

    let registers = []
    try {
      const result = await this.$axios.$get(`/webpos/stores/${storeID}/registers`)

      if (!result || !result.apiMessages || result.apiMessages.hasErrors) {
        this.$toast.error('Error while fetching registers')
      }
      else {
        registers = result.value
        commit('setRegisters', { registers })
      }
    }
    catch {
      this.$toast.error('Error while fetching registers')
    }

    return registers

  },

}
