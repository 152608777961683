import {frontends} from "~/enums/frontends";
import {formActions} from "~/enums/formActions"

const initState = () => ({
  contents: [],
  contentsBeforeFiltering: []
})

export const state = initState

export const mutations = {
  setContents(state, contents) {
    state.contents = contents
    state.contentsBeforeFiltering = contents
  },
  filterContents(state, contents) {
    state.contents = state.contentsBeforeFiltering.filter(content=> {
      if(contents.filterType === 'Name') {
        return contents.items.find(pContent=>pContent.id===content.id);
      }
      return contents.items.find(pContent=>pContent.id===content.contentTypeID);
    })
  }
}

export const actions = {
  fetchContents({commit}) {
    return this.$axios.$get('manage/contents/getForFrontend/'+frontends.WEB_POS).then(contents => {
      commit('setContents', contents)
    })
  },

  saveContentWithFields({commit}, {content, value}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const returnedContent = await this.$axios.$post(
            `/manage/contents/content-with-fields`,
            content
          )
          resolve({content: returnedContent})
          this.$toast.success(
            this.$i18n.t('saved_successfully').replace('{name}', content.content.name)
          )
        },
        formActions.Save,
        content.name
      )
    })
  },

  saveContent({commit}, {content, value}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const returnedContent = await this.$axios.$post(
            `/manage/contents`,
            content
          )
          resolve({content: returnedContent})
          this.$toast.success(
            this.$i18n.t('saved_successfully').replace('{name}', content.name)
          )
        },
        formActions.Save,
        content.name
      )
    })
  },

  deleteContentById({commit}, content) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const returnedContent = await this.$axios.$delete(`/manage/contents/${content.id}`)
          resolve({content: returnedContent})
          this.$toast.success(
            this.$i18n.t('deleted_successfully').replace('{name}', content.name)
          )
        },
        formActions.Delete,
        content.name
      )
    })
  },

  filterStateContents({commit}, filters) {
    commit('filterContents', filters)
  }

}
