import {mapFilters} from "@/utils/helpers";
const initState = () => ({
})

export const state = initState

export const getters = {
}

export const mutations = {
}

export const actions = {

  async fetchItems({}, {filters}){
    const url = `/webpos/items?${new URLSearchParams(filters).toString()}`;

    let items = [];
    try {
      const result = await this.$axios.$get(url)
      if(result && !result.hasErrors){
        items = result.items;
      }
      else{
        this.$toast.error("Error while getting items");
      }
    }
    catch (error) {
      if(error.response && error.response.status === 404) {
        filters.productID > 0
          ? this.$toast.error("The product does not exist")
          : this.$toast.error("The catalog does not exist");
        return items
      }
      this.$toast.error("Error while getting items");
    }
    return items
  },

  async getItemByID({}, itemID){
    const url = `/webpos/items/${itemID}`;
    let item = {};
    try {
      const result = await this.$axios.$get(url)
      if(result && result.apiMessages.hasErrors){
        this.$toast.error("Error while getting item");
      }
      else{
        item = result.value;
      }
    }
    catch (error) {
      if(error.response && error.response.status === 404) {
        this.$toast.error("The item does not exist");
        return item
      }
      this.$toast.error("Error while getting item");
    }
    return item
  },

  async getItemQuantitiesByLocations({}, {itemID, storeID}){
    let result = [];

    try{
      const response = await this.$axios.$get(`/webpos/items/${itemID}/locations/quantities?storeID=${storeID}`);
      if(response && response.apiMessages.hasErrors){
        this.$toast.error("Error while getting item quantities");
      }
      else{
        result = response.value;
      }
    }
    catch (error) {
      this.$toast.error("Error while getting item quantities");
    }

    return result;
  },

  async fetchItemImages({}, itemID){
    let result = [];

    try{
      const response = await this.$axios.$get(`/webpos/items/${itemID}/images`);
      if(response && response.apiMessages.hasErrors){
        this.$toast.error("Error while getting item images");
      }
      else{
        result = response.value;
      }
    }
    catch (error) {
      this.$toast.error("Error while getting item images");
    }

    return result;
  },

  async fetchItemsForAddComponents({}, {filters, pagingInfo}){
    let items = [];
    try {
      let filter = mapFilters(filters, pagingInfo.pageNo, pagingInfo.pageSize, pagingInfo.sortBy, pagingInfo.sortAsc);
      const url = `/webpos/items/for-add-components?${new URLSearchParams(filter).toString()}`;
      const result = await this.$axios.$get(url)
      if(result && result.items && !result.hasErrors){
        items = result;
      }
      else{
        this.$toast.error('Error while getting inventory items');
      }
    }
    catch (error) {
      this.$toast.error('Error while getting inventory items');
    }

    return items
  },

  async transferItems({}, {actualItemID, transferItems}){
    let successTransfer = true;
    try {
      const url = `/webpos/items/${actualItemID}/transfer`;
      await this.$axios.$post(url, transferItems)
      this.$toast.success("Items transferred successfully");

    }
    catch (error) {
      successTransfer = false;
      this.$toast.error("Error while transferring items");
    }

    return successTransfer
  },
}
