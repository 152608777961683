import {mapFilters} from "@/utils/helpers";

const initState = () => ({
})

export const state = initState

export const getters = {
}

export const mutations = {
}

export const actions = {

  async fetchOrders({commit, state}, {pagingInfo, filters}){
    const url = `/webpos/sales-order/`;

    let filter = mapFilters(filters, pagingInfo.pageNo, pagingInfo.pageSize, pagingInfo.sortBy, pagingInfo.sortAsc);
    const result = await this.$axios.$post(url, filter);

    if(result && !result.hasErrors){
      return result.items;
    }
    else{
      this.$toast.error('Error while getting orders.')
      return [];
    }
  },

  async createWebPOSOrder({commit}, {orderData}) {
    try{
      const response = await this.$axios.$post(`/webpos/sales-order/create-sales-order`, orderData)
      if(response && !response.status){
        return response;
      }
    }
    catch(error){
      if(error.response && error.response.status === 404) {
        this.$toast.error("The customer does not exist");
      }
    }
    return null;
  },

}
