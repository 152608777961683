import * as signalR from "@microsoft/signalr";

export default ({ app }, inject) => {
  const defaultAPI = 'http://localhost:50000/api/'
  const apiBaseUrl = app.$config.axios.browserBaseURL || defaultAPI

  const connection = new signalR.HubConnectionBuilder()
    .withUrl( `${apiBaseUrl}signalr`,{skipNegotiation: true,transport: signalR.HttpTransportType.WebSockets}) // The URL where your SignalR hub is hosted
    .configureLogging(signalR.LogLevel.Information)
    .build();

  connection.start().then(() => {
    console.log("Connection established");
  }).catch(err => console.error(err));

  inject('connection', connection);
}
