import {formActions} from '@/enums/formActions'

export default (ctx, inject) => {
  const catchPlugin = async function (
    func,
    operation = formActions.Load,
    name = ''
  ) {
    try {
      const data = await func()
      return {data}
    } catch (error) {
      this.$toast.error(
        this.$i18n
          .t('server_error_occured_while')
          .replace('{operation}', this.$i18n.t(`form_action_${operation}_text`))
          .replace('{name}', name ? ` - ${name}` : '')
      )
      return {error}
    }
  }
  ctx.$catch = catchPlugin
  inject('catch', catchPlugin)
}
