import {customerGroupCodes} from "@/enums/customerGroupCodes";
import {customerTypes} from "@/enums/customerTypes";

const initState = () => ({
  customers: [],
  customerListItems: [],
  vendorListItems: [],
  lastCustomerID: 0
})

export const state = initState

export const getters = {
  getCustomers: (state) => () => {
    return state.customers
  },
  getLastCustomerID: (state) => () => {
    return state.lastCustomerID
  },
  getCustomerListItems: (state) => () => {
    return state.customerListItems
  },
  getVendorListItems: (state) => () => {
    return state.vendorListItems
  },
}

export const mutations = {
  setCustomers(state, {customers}){
    state.customers = customers
  },
  concatNewCustomers(state, {newCustomers}){
    state.customers = state.customers.concat(newCustomers)
  },
  updateCustomerFromList(state, {updatedCustomer}){
    if(state.customers && state.customers.length > 0
      && updatedCustomer && updatedCustomer.id){
        let customer = state.customers.find(x => x.id === updatedCustomer.id)
        if(customer){
          customer.customerName =  updatedCustomer.customerType = customerTypes.PERSON
            ? `${updatedCustomer.firstName || ''} ${updatedCustomer.lastName || ''}`
            : updatedCustomer.companyName
          customer.email = updatedCustomer.email
          customer.phone = updatedCustomer.phone
        }
    }
  },
  setLastCustomerID(state, {lastCustomerID}){
    state.lastCustomerID = lastCustomerID
  },
  setCustomerListItems(state, {customerListItems}){
    state.customerListItems = customerListItems
  },
  setVendorListItems(state, {vendorListItems}){
    state.vendorListItems = vendorListItems
  },
}

export const actions = {
  async getCustomers({commit, getters}, getVendors = false) {
    let customers = getters.getCustomers()
    let lastCustomerID = getters.getLastCustomerID()

    try {
      const result = await this.$axios.$post(
        'webpos/customer',
        {
          lastCustomerID: customers.length > 0 ? lastCustomerID : 0,
          customerGroupCode: getVendors ? customerGroupCodes.VENDOR : customerGroupCodes.CUSTOMER
        });

      if (result && !result.hasErrors && result.value) {
        if (result.value.customers.length > 0) {
          if (customers && customers.length > 0) {
            commit('concatNewCustomers', {newCustomers: result.value.customers});
          } else {
            commit('setCustomers', {customers: result.value.customers});
          }
          commit('setLastCustomerID', {lastCustomerID: result.value.lastCustomerID})
        }
      } else {
        this.$toast.error('Error while getting customers');
      }
    } catch (e) {
      this.$toast.error('Error while getting customers');
    }

    customers = getters.getCustomers()
    return customers;
  },

  async getCustomerListItems({commit, dispatch, getters}, getVendors = false) {
    const customers = await dispatch('getCustomers', getVendors);
    let customerListItems = getVendors ? getters.getVendorListItems() : getters.getCustomerListItems

    if (customers && customers.length > 0 && customerListItems.length !== customers.length) {
      customerListItems = customers.map((customer) => {
        return {
          value: customer.id,
          text: customer.customerName,
        }
      });
    }
    commit(getVendors ? 'setVendorListItems' : 'setCustomerListItems', customerListItems)
    return customerListItems;
  },
}
