const initState = () => ({
})

export const state = initState

export const getters = {
}

export const mutations = {

}

export const actions = {
  async uploadTmpFile({commit}, {file})
  {
    let tmpFileUrl= null
    try {
      const formData = new FormData()
      formData.append('file', file)
      const result = await this.$axios.$post('/upload/tmp-file', formData)
      if (result && result.tmpFileUrl) {
        tmpFileUrl = result.tmpFileUrl
      }
    }
    catch{
      this.$toast.error("Error while uploading file")
    }

    return tmpFileUrl;
  },

  async deleteTmpFile({commit}, {file}){
    try{
      await this.$axios.$post('/upload/delete-tmp-file', file)
    }
    catch{
      console.error("Error while deleting file")
    }
  }

}
