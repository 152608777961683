const initState = () => ({
  companyDetails: null,
})

export const state = initState

export const mutations = {
  setCompanyDetails(state, {companyDetails}) {
    state.companyDetails = companyDetails
  },
  reset(state) {
    Object.assign(state, initState())
  },
}

export const actions = {
  fetchCompanyDetails({commit}) {
    return this.$axios.$get('/CPCompanyDetails').then((companyDetails) => {
      commit('setCompanyDetails', {
        companyDetails,
      })
    })
  },
}
