import {formActions} from "@/enums/formActions";
import {frontends} from "@/enums/frontends";

const initState = () => ({
  globalSettings: [],
})

export const state = initState

export const getters = {
  getGlobalSettings: (state) => (searchStr) => {
    const regexp = new RegExp(searchStr, 'i') // TODO add filtering back
    if (searchStr) {
      const result = []
      for (const group of state.globalSettings) {
        const adminSettings = group.adminSettings.filter(
          (x) => regexp.test(x.name) || regexp.test(x.code)
        )
        if (adminSettings.length > 0) {
          const returnedGroup = {...group}
          returnedGroup.adminSettings = adminSettings
          result.push(returnedGroup)
        }
      }
      return result
    }
    return state.globalSettings
  },
}

export const mutations = {
  setGlobalSettings(state, {globalSettings}) {
    state.globalSettings = globalSettings
  },
  setGlobalSettingFieldValue(state, {setting, value}) {
    setting.value = value
  },
}

export const actions = {
  async fetchGlobalSettings({commit, state, }, {}) {
    return await this.$axios
      .$get('/admin-settings/cp/settings', {
        params: {
          frontendId: frontends.WEB_POS
        }
      })
      .then((result) => {
        commit('setGlobalSettings', {globalSettings: result})
      })
  },
  updateGlobalSettingFieldValue({commit, dispatch}, {setting, value}) {
    this.$catch(
      async () => {
        await this.$axios.$put(`/admin-settings/cp/settings/${setting.code}`, {
          value,
        })
        commit('setGlobalSettingFieldValue', {setting, value})
        dispatch('settings/fetchAdminSettings', null, {root: true})
        if(value === 'true'){
          this.$toast.success(
            this.$i18n.t('saved_successfully').replace('{name}', setting.name)
          )
        }
        else{
          this.$toast.success(
            this.$i18n.t('saved_successfully').replace('{name}', setting.name).replace('Allow', 'Disabled')
          )
        }
      },
      formActions.Save,
      setting.name
    )
  },
  async getAdminSettingsByGroupCode({}, {groupCode}){
    const url = `webpos/admin-settings/get-by-group-code/${groupCode}`
    let response = await this.$axios.$get(url, {
      params: {
        frontendId: frontends.WEB_POS
      }
    });
    if(response && !response.hasErrors){
      return response.value;
    }
    else {
      this.$toast.error(response.errorMessages[0].value);
    }
  },

  async getAdminSettingValueByTheCode({},adminSettingCode){
    let adminSettingValue = 0;
    try{
      const response = await this.$axios.$get(`webpos/admin-settings/${adminSettingCode}/value`);
      if(!response || response.apiMessages.hasErrors){
        this.$toast.error("Error while getting admin setting value");
      }
      else{
        adminSettingValue = response.value
      }
    }
    catch(e){
      this.$toast.error("Error while getting admin setting value");
    }
    return adminSettingValue;
  }

}
