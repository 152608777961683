const initState = () => ({
})

export const state = initState

export const getters = {
}

export const mutations = {
}

export const actions = {
  async getLocations({commit}, filter) {
    let locations = []
    try {
      const result = await this.$axios.$get(`/webpos/location?${new URLSearchParams(filter).toString()}`)

      if (result && !result.hasErrors) {
        locations = result.value
      }
      else {
        this.$toast.error('Error while getting locations')
      }
    }
    catch {
      this.$toast.error('Error while getting locations')
    }

    return locations
  },

  async getLocationsForFilter({commit, dispatch}, filter) {
    let locations = []

    const locationsResult = await dispatch('getLocations', filter)
    if(locationsResult?.length){
      locations = locationsResult.map(location => {
        return {
          value: location.id,
          text: location.name,
        }
      })
    }

    return locations
  },
}
