const initState = () => ({
  transactions: [],
})

export const state = initState

export const getters = {
  getTransactions(state) {
    return state.transactions
  }
}


export const mutations = {
  setTransactions(state, {transactions}) {
    state.transactions = transactions
  },
}

export const actions = {
  async fetchTransactions({commit}, {filters, page}) {
    let pageNumber= page ? page : 0
    filters = filters ? filters : {
      formDate: null,
      toDate: null,
      saleNumber: undefined,
    }
    let response = await this.$axios.$post('webpos/registers/transactions', {
        createdWhen: filters.fromDate,
        dateTo: filters.toDate,
        salesOrderCode: filters.saleNumber  ? filters.saleNumber : null,
        pageNo: pageNumber,
        pageSize: 50,
        storeID: filters.storeID,
        registerID: filters.registerID
      })

      if (response.hasErrors == false){
        commit('setTransactions', {transactions: response.value})
      }
      else {
        this.$toast.error("Error while getting transactions")
      }


    return response

  },


}
