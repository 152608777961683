const initState = () => ({
  currentStore: {
    id: null,
    name: ''
  },
  switchedUser: false
})

export const state = initState

export const mutations = {
  setCurrentStore(state, {store}) {
    state.currentStore = store
  },
  setSwitchedUser(state, {value}){
    state.switchedUser = value
  }
}

export const actions = {
  setCurrentStore({commit}, store) {
    commit('setCurrentStore', {store: store })
  },
  resetStore({commit}) {
    commit('setCurrentStore', {store: initState() })
  }
}
