const initState = () => ({
  currentRegister: {
    id: null,
    name: '',
    code: null,
    isOpen: false,
  }
})

export const state = initState

export const mutations = {
  setCurrentRegister(state, {register}) {
    state.currentRegister = register
  },
  updateCurrentRegisterOpeningStatus(state, {isOpen}) {
    state.currentRegister.isOpen = isOpen
  }
}


export const actions = {
  setCurrentRegister({commit}, register) {
    commit('setCurrentRegister', {register: register })
  },

  resetCurrentRegister({commit}) {
    commit('setCurrentRegister', {register: initState() })
  },

  async registerOperation({commit}, {registerOperation}) {
    try{
      await this.$axios.$post(`/webpos/registers/operations`, registerOperation)
    }
    catch{
      this.$toast.error('Error while adding operation to register')
    }

  },

  async getDailyPaymentTotal({commit}, registerId) {
    try{
      let response = await this.$axios.$get(`/webpos/registers/${registerId}/get-daily-operations-total`);
      if(response && response.errorMessages !== null ){
        this.$toast.error(response.errorMessages[0])
        return Number.MIN_SAFE_INTEGER// To make sure customer credit wont be created after error
      }
      else{
        return response.value;
      }
    }
    catch{
      this.$toast.error('Error while fetching daily payment total')
      return Number.MIN_SAFE_INTEGER
    }
  },

  async registerPayment({commit}, {registerPayment}) {
    try{
      let response = await this.$axios.$post(`/webpos/registers/register-payment`, registerPayment)

      if (response && (response.hasErrors || response.errorMessages !== null)) {
        this.$toast.error("Error occured while adding payment to register!")
      }
    }
    catch{
      this.$toast.error('Error while adding payment to register')
    }
  },

}

