export const jobTypeCodes = {
  JobOrder: 'JO',
  SalesOrder: 'SO',
  PriceQuote: 'PQ',
  POSSalesOrder: 'POS_SO',
  LayawayOrder: 'POS_LSO',
  RepairJobOrder: 'REPJO',
  RepairPriceQuote: 'REPPQ',
  CustomJobOrder: 'POS_COJO',
  CustomPriceQuote: 'POS_COPQ',
}
