const initState = () => ({
	stores: [],
})

export const state = initState

export const getters = {
	getStores: (state) => () => {
		return state.stores
	}
}

export const mutations = {
	setStores(state, {stores}) {
		state.stores = stores
	},
  resetStores(state){
    state.stores = []
  }
}

export const actions = {
  async fetchStores({commit}) {
    let stores = []
    try {
      const result = await this.$axios.$get('user/stores')

      if (result && result.value) {
        stores = result.value
        commit('setStores', { stores })
      }
      else {
        this.$toast.error('Error while fetching stores')
      }
    }
    catch {
      this.$toast.error('Error while fetching stores')
    }

    return stores
  },
  async checkStoresByUsername({commit}, username) {
      const result = await this.$axios.$get('user/stores-by-user-name/'+ username)

      return result && result.length > 0
  }
}
