const cookieparser = process.server ? require('cookieparser') : undefined

export default function ({$axios, store, req}) {
  $axios.onRequest((config) => {
    // console.log('axios config', config)
    // console.log('user', store.state)
    // console.log(store.$auth.$storage)
    // if (config.url.includes('refreshtoken')) {
    //   config.data.token = store.$auth.local.token.get()
    // }

    /* store._vm.$nextTick(() => { // start loader line on top of page
      store._vm.$nuxt.$loading.start()
      return config
    }) */

    if (!process.server) {
      if (!config.url.includes('Dashboard')) {
        store.commit('setLoading', {loading: true})
      }
    }
    if (config.url.includes('refreshtoken')) {
      if (!process.server) {
        try {
          config.data.token = localStorage['auth._token.local'].replace(
            'Bearer ',
            ''
          )
        } catch (err) {}
      }
      if (process.server) {
        if (req.headers.cookie) {
          const parsedCookie = cookieparser.parse(req.headers.cookie)
          try {
            config.data.token = parsedCookie['auth._token.local'].replace(
              'Bearer ',
              ''
            )
          } catch (err) {}
        }
      }
    }
    // console.log('config', config)
    console.log('Making request to ' + config.url)
  })

  $axios.onResponse((response) => {
    if (!process.server) {
      store.commit('setLoading', {loading: false})
    }
    /* store._vm.$nextTick(() => {
      store._vm.$nuxt.$loading.finish()
      return response
    }) */
  })

  $axios.onError((error) => {
    if (!process.server) {
      store.commit('setLoading', {loading: false})
    }
    /* store._vm.$nextTick(() => {
      store._vm.$nuxt.$loading.finish()
      return Promise.reject(error)
    }) */
    const code = parseInt(error.response && error.response.status)
    if (code === 400) {
      // return '/400'
    }
    console.log('axios error', error)
  })
}
