import {mapFilters} from "@/utils/helpers";

const initState = () => ({
})

export const state = initState

export const getters = {
}

export const mutations = {
}

export const actions = {

  async fetchLayawayOrders({commit, state}, {pagingInfo, filters}){
    const url = `/webpos/layaway-order/`;
    let layaways = [];

    let filter = mapFilters(filters, pagingInfo.pageNo, pagingInfo.pageSize, pagingInfo.sortBy, pagingInfo.sortAsc);
    const result = await this.$axios.$post(url, filter);

    if(result && !result.hasErrors){
      layaways= result.items;
    }
    else{
      this.$toast.error('Error while getting layaways.')
    }
    return layaways;
  },

  async createWebPOSLayawayOrder({commit}, {orderData}) {
    try{
      const response = await this.$axios.$post(`/webpos/layaway-order/create-layaway-order`, orderData)
      if(response && !response.status){
        return response;
      }
      else{
        this.$toast.error(response.vResult.errorMessage);
      }
    }catch(error){
      if(error.response) {
        this.$toast.error(error.response.data.Message);
        return;
      }
      this.$toast.error('Error while creating layaway order.');
    }
  },

  async fetchPaymentTerms({commit, state}) {
    const result = await this.$axios.$get('webpos/layaway-order/payment-terms')
    if(result && !result.hasErrors){
      return result.value;
    }
    else{
      this.$toast.error('Error while getting payment terms')
    }
  },

  async fetchLayawayPayments({commit, state}, {layaway}) {
    let payments = [];
    try{
      const result = await this.$axios.$post(`/webpos/layaway-order/layaway-payments/`, layaway);
      if(result && !result.hasErrors){
        payments = result.items;
      }
      else{
        this.$toast.error('Error while getting layaway payments')
      }
    }
    catch{
      this.$toast.error('Error while getting layaway payments')
    }
    finally {
      return payments;
    }

  },

  async getLayawayOrderDetailsByID({commit}, {orderID}) {
    const url = `/webpos/layaway-order/detail`;
    const result = await this.$axios.$get(`${url}/${orderID}`);

    if(result && !result.hasErrors){
      return result.value;
    }
    else{
      this.$toast.error('Error while getting layaway order details.')
      return [];
    }
  }
}
