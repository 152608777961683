const initState = () => ({
  total : 0,
  totalSC : 0,
  paymentMethod: null,
  salesTax: 0,
  appliedAmount: 0,
  orderActionInProgress : false
})

export const state = initState

export const getters = {
  getTotal: (state) => state.total,
  getPaymentMethod: (state) => state.paymentMethod,

}

export const setters ={
}

export const mutations = {
  setTotal(state, {total}){
    state.total = total
  },
  setTotalSC(state, {totalSC}) {
    state.totalSC = totalSC
  },
  setPaymentMethod(state,{paymentMethod}){
    state.paymentMethod = paymentMethod
  },
  setSalesTax(state, {salesTax}) {
    state.salesTax = salesTax
  },
  setAppliedAmount(state, {appliedAmount}) {
    state.appliedAmount = appliedAmount
  },
  setOrderActionInProgress(state, inProgress){
    state.orderActionInProgress = inProgress
  },
}

export const actions = {

  async getSalesTax({commit},customerId){
    const salesTax = await this.$axios.$get(
      '/webpos/shopping-cart/get-sales-tax/' + customerId
    )
    commit('setSalesTax', {salesTax: salesTax.value})
    return salesTax
  },

  async getPaymentTypes({commit}){
    return  await this.$axios.$get(
      '/paymenttypes/'
    )
  }
}

