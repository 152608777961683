import {formActions} from '@/enums/formActions'

const initState = () => ({})

export const actions = {
  fetchDataSources({commit}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const dataSources = await this.$axios.$get(`/manage/data-sources`)
          resolve({dataSources})
        },
        formActions.Load,
        'dataSources'
      )
    })
  },
}
