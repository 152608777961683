const initState = () => ({

})

export const state = initState

export const getters = {

}

export const mutations = {

}

export const actions = {

  async getSalesRepsForFiltering({commit}, storeID){
    let salesReps = []
    let url = 'webpos/users/sales-reps'
    if(storeID > 0){
      url = `${url}/?storeID=${storeID}`
    }
    try{
      const response = await this.$axios.$get(url)
      if(response.status > 0 || response.vResult.errorMessage.length > 0){
        this.$toast.error("Error while getting the sales reps for filter.")
        return []
      }
      salesReps = response.value.map((user) => {
        return {
          value: user.id,
          text: user.fullName,
        }
      })
    }
    catch(e){
      this.$toast.error("Error while getting the sales reps for filter.")
    }
    return salesReps
  }
}
