const initState = () => ({
})

export const state = initState

export const mutations = {

}

export const actions = {
  async insertNMICard({commit},{card, customerID} ){
    try {
      let result = await this.$axios
        .$post(`webpos/credit-card/nmi-card/${customerID}`, card)
      return result;
    }catch (e) {
      if (e.response) {
        this.$toast.error(e.response.data.Message);
      }  else {
        this.$toast.error(e);
      }
      return null
    }

  },
}
