import {jobTypeCodes} from "@/enums/jobTypeCodes";
import {labelListCodes} from "@/enums/labelListCodes";

const initState = () => ({
})

export const state = initState

export const actions= {

  async getSalesTax({}, customerID){
    let salesTaxList = []
    try{
      const result = await this.$axios.$get(`/webpos/shopping-cart/get-sales-tax/${customerID}`);

      if(result && !result.hasErrors && result.value){
        salesTaxList = result.value;
      }
      else{
        this.$toast.error("Error while getting sales tax.");
      }

    }
    catch{
      this.$toast.error("Error while getting sales tax.");
    }

    return salesTaxList;
  },
}
